import { utilsLayoutClass } from '@/utils/computed-classes'

export default {
  name: 'Grid',
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    },
    smCols: {
      type: String,
      default: '1'
    },
    mdCols: {
      type: String,
      default: '2'
    },
    lgCols: {
      type: String,
      default: '3'
    },
    smLayout: {
      type: String,
      default: 'even'
    },
    mdLayout: {
      type: String,
      default: 'even'
    },
    lgLayout: {
      type: String,
      default: 'even'
    },
    smGap: {
      type: String,
      required: false,
      default: '8'
    },
    mdGap: {
      type: String,
      required: false,
      default: '14'
    },
    lgGap: {
      type: String,
      required: false,
      default: '24'
    },
    content: {
      type: Array,
      required: false,
      default () {}
    },
    hideMobile: {
      type: Boolean,
      required: false,
      default: false
    },
    hideTablet: {
      type: Boolean,
      required: false,
      default: false
    },
    hideDesktop: {
      type: Boolean,
      required: false,
      default: false
    },
    smVerticalMargin: {
      type: String,
      default: ''
    },
    mdVerticalMargin: {
      type: String,
      default: ''
    },
    lgVerticalMargin: {
      type: String,
      default: ''
    },
    mobileBleed: {
      type: Boolean,
      required: false,
      default: false
    },
    tabletBleed: {
      type: Boolean,
      required: false,
      default: false
    },
    desktopBleed: {
      type: Boolean,
      required: false,
      default: false
    },
    mobileHideComponent: {
      type: Array,
      required: false,
      default (rawProps) {
        return []
      }
    },
    tabletHideComponent: {
      type: Array,
      required: false,
      default (rawProps) {
        return []
      }
    },
    desktopHideComponent: {
      type: Array,
      required: false,
      default (rawProps) {
        return []
      }
    }
  },
  computed: {
    gapClasses () {
      return `gap-${this.smGap} md:gap-${this.mdGap} lg:gap-${this.lgGap}`
    },
    mobileDisplaySize () {
      return parseInt(100 / this.smCols) + 1 + 'vw'
    },
    desktopDisplaySize () {
      return parseInt(100 / this.lgCols) + 1 + 'vw'
    }
  },
  methods: {
    layoutClass (index) {
      return utilsLayoutClass(index, this)
    }
  }
}
