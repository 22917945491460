var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"grid grid-cols-12",class:[
    _vm.gapClasses,
    _vm.mobileBleed ? '-mx-4' : '',
    _vm.tabletBleed ? 'md:-mx-4' : 'md:mx-0',
    _vm.desktopBleed ? 'lg:-mx-14' : 'lg:mx-0',
    _vm.hideMobile ? 'hidden' : 'grid',
    _vm.hideTablet ? 'md:hidden' : 'md:grid',
    _vm.hideDesktop ? 'lg:hidden' : 'lg:grid',
    'my-' + _vm.smVerticalMargin,
    'md:my-' + _vm.mdVerticalMargin,
    'lg:my-' + _vm.lgVerticalMargin
  ]},[_vm._l((_vm.content),function(block,index){return [_c('div',{key:index,class:[
        _vm.layoutClass(index),
        _vm.mobileHideComponent.includes((index + 1).toString()) ? 'hidden' : 'block',
        _vm.tabletHideComponent.includes((index + 1).toString()) ? 'md:hidden' : 'md:block',
        _vm.desktopHideComponent.includes((index + 1).toString()) ? 'lg:hidden' : 'lg:block']},[_c(block.component,_vm._b({directives:[{name:"editable",rawName:"v-editable",value:(block),expression:"block"}],key:block.id,tag:"component",attrs:{"mobile-display-size":_vm.mobileDisplaySize,"desktop-display-size":_vm.desktopDisplaySize}},'component',block,false))],1)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }